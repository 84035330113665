<template>
  <div id="toast-container" v-if="$store.state.toast.toast.length > 0">
    <template v-for="toast in $store.state.toast.toast">
      <component-toast :key="toast.id"
                       :type="toast.type"
                       :title="toast.title"
                       :message="toast.message"
                       :id="toast.id"/>
    </template>
  </div>
</template>
<script>
import ComponentToast from './ComponentToast.vue';

export default {
  name: "ComponentToastContainer",
  components: { ComponentToast }
}
</script>
<style scoped lang="scss">
#toast-container {
  position: fixed;
  padding-top: 60px;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2000;
  background-color: transparent;
  pointer-events: none;

  .toast {
    pointer-events: all;
    background-color: #e9ecef;
    animation-name: slide-in;
    animation-duration: .6s;
    animation-delay: -.2s;
  }

  @-webkit-keyframes slide-in {
    from {margin-left: 400px;}
    to {margin-left: 0;}
  }

  @keyframes slide-in {
    from {margin-left: 400px;}
    to {margin-left: 0;}
  }
}
</style>