<template>
  <nav class="navbar navbar-expand bg-white text-dark navbar-light fixed-top shadow-sm px-4 py-1"
       :class="{ 'navbar-searching': searching }">
    <button class="navbar-toggler d-block d-md-none"
            type="button"
            aria-controls="top-navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="$store.commit('sidebar/openSidebar', '100%')">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div id="top-navigation" class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav ms-2">
        <li class="nav-item dropdown ms-2" v-if="user !== null">
          <a class="nav-link dropdown-toggle p-0 d-inline-flex align-items-center h-100"
             data-bs-toggle="dropdown"
             id="userDropdown"
             role="button"
             aria-expanded="false">
            <v-gravatar id="user-avatar" class="me-2" :username="user.username" :size="24"/>
            <span class="font-weight-bold text-dark d-none d-md-block">{{ user.username }}</span>
          </a>
          <div class="dropdown-menu position-right" aria-labelledby="userDropdown">
            <router-link class="dropdown-item" :to="{ name: 'profile' }">{{ $tc('profile', 1) }}</router-link>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" @click="onSignOut">{{ $t('sign-out') }}</button>
          </div>
        </li>
        <li class="nav-item dropdown ms-2">
          <a class="nav-link dropdown-toggle text-dark position-relative"
             data-bs-toggle="dropdown"
             role="button"
             aria-haspopup="true"
             aria-expanded="false">
            <font-awesome-icon :icon="['fas', 'language']" size="lg"/>
          </a>
          <div class="dropdown-menu position-right">
            <button class="dropdown-item" @click="$i18n.locale = 'de-DE'">Deutsch</button>
            <button class="dropdown-item" @click="$i18n.locale = 'en-US'">English (US)</button>
          </div>
        </li>
        <li class="nav-item ms-2">
          <a class="nav-link text-dark position-relative" role="button">
            <font-awesome-icon :icon="['far', 'bell']" size="lg"/>
          </a>
        </li>
        <li class="nav-item ms-2">
          <a class="nav-link text-dark position-relative" role="button">
            <font-awesome-icon :icon="['far', 'question-circle']" size="lg"/>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "ComponentNavbar",
  data() {
    return {
      searching: false,
      term: ''
    }
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    user() {
      return this.$store.state.ifs.user;
    }
  },
  methods: {
    onSearch() {
      console.log(this.term);
    },
    onSignOut() {
      this.$store.dispatch('ifs/signOut');
      this.$router.push({ name: 'sign-in' });
    }
  },
  beforeMount() {
    this.version = process.env.VUE_APP_VERSION;
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

nav {
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom-color .3s;
     -moz-transition: border-bottom-color .3s;
      -ms-transition: border-bottom-color .3s;
       -o-transition: border-bottom-color .3s;
          transition: border-bottom-color .3s;

  &.navbar-searching {
    border-bottom-color: $primary;
  }
}

.position-right {
  left: unset;
  right: 0;
}

@media only screen and (max-width: 768px) {
  nav {

    &.navbar-searching {
      border-bottom: 2px solid transparent;
    }

    #search-bar {
      position: absolute;
      border-bottom: 2px solid transparent;
      top: 50px;
      left: 0;
      border-bottom: 2px solid transparent;
      -webkit-transition: border-bottom-color .3s;
         -moz-transition: border-bottom-color .3s;
          -ms-transition: border-bottom-color .3s;
           -o-transition: border-bottom-color .3s;
              transition: border-bottom-color .3s;
      -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
         -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
              box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

      &.navbar-searching {
        border-bottom-color: $primary;
      }
    }
  }
}

</style>