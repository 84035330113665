<template>
  <nav>
    <div class='collapse-sidebar-horizontally'>
      <div class="collapse-sidebar-inner" @click="$store.commit('sidebar/toggleSidebar')">
        <font-awesome-icon :icon="sidebarCollapsed ? 'chevron-left' : 'chevron-right'"
                           size="lg"
                           class="collapse-sidebar-arrow"/>
      </div>
    </div>
    <div id="icon-container" class="d-flex justify-content-between">
      <router-link to="/">
        <img class="d-block mw-100"
             :class="logoClass"
             :src="sidebarCollapsed ? require('@/assets/img/ifs-logo-208x34.png') : require('@/assets/img/ifs-icon-37x37.png')"
             :alt="appTitle">
      </router-link>
      <font-awesome-icon icon="times"
                         size="lg"
                         class="text-white m-2 d-block d-md-none cursor-pointer"
                         @click="$store.commit('sidebar/closeSidebar', '0px')"/>
    </div>

    <ul class="nav flex-column">
      <template v-for="(content, i) in contents">
        <li class="nav-item w-100 sidebar-item" :title="content.name" :key="'nav-item-' + i">
          <router-link class="nav-link text-left" active-class="active" :to="{ name: content.route }">
            <font-awesome-icon :icon="content.icon" class="me-2" size="lg"/>
            <span class="group-label" :class="{ hide: !sidebarCollapsed }">{{ content.name }}</span>
          </router-link>
        </li>
      </template>
    </ul>

  </nav>
</template>

<script>
export default {
  name: "ComponentSidebar",
  computed: {
    sidebarCollapsed() {
      return this.$store.state.sidebar.open;
    },
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    contents() {
      return this.$store.state.sidebar.content;
    }
  },
  data() {
    return {
      logoClass: {
        'p-1': this.$store.state.sidebar.width === '45px',
        'p-2': this.$store.state.sidebar.width === '224px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

#sidebar nav > ul {
  overflow-y: scroll;
  height: calc(100vh - 69px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

nav {
  #icon-container {
    position: relative;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .collapse-sidebar-horizontally {
    height: 100%;
    width: 15px;
    position: absolute;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .collapse-sidebar-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 15px;
      background-color: rgba(194, 195, 191, .5);
      transition: height .3s ease;

      &:hover {
        height: 100vh;
        transition: height .3s ease;
        cursor: pointer;
      }
    }
  }

  .nav {
    flex-wrap: nowrap;
  }

  .sidebar-item .group-label.hide {
    display: none;
  }

  .sidebar-item {

    a {
      display: block;
      padding: 15px;
      color: lighten($dark, 10%);

      &.active {
        background-color: rgba(255,255,255,.2);
        color: $primary;
      }

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .collapse-sidebar-horizontally {
    display: none !important;
  }
}
</style>