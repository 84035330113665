<template>
  <div class="border-top w-100 text-center">
    <a href="https://www.stina-global.com"
       class="text-dark px-2 text-decoration-none"
       target="_blank">stina-global.com</a>
    <a href="#" class="text-dark px-2 text-decoration-none">Cookies</a>
  </div>
</template>

<script>
export default {
  name: "ComponentFooter"
}
</script>

<style scoped lang="scss">

</style>