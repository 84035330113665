<template>
  <div class="toast show" role="alert">
    <div class="toast-header bg-white">
      <strong class="me-auto">
        <font-awesome-icon icon="circle" class="me-1" :class="[iconBackground]"/>
        {{ title }}
      </strong>
      <button id="close-button"
              class="btn-close ms-2 mb-1"
              type="button"
              @click="$store.commit('toast/remove', id)">
      </button>
    </div>
    <div class="toast-body bg-white">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  name: "ComponentToast",
  computed: {
    iconBackground() {
      return {
        'text-warning': this.$props.type === 'warning',
        'text-danger': this.$props.type === 'danger',
        'text-info': this.$props.type === 'info',
        'text-success': this.$props.type === 'success'
      }
    }
  },
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    message: {
      type: String
    },
    type: {
      type: String
    },
  }
}
</script>
<style scoped lang="scss">

</style>